<template>
  <article
    :style="{ backgroundColor: item.backgroundColor || undefined }"
    class="bg-primary overflow-hidden bg-[url(/images/bg-pawprints.svg)] py-3"
  >
    <div
      class="container relative grid justify-items-center gap-2"
      :class="
        item.buttonHreff
          ? 'md:grid-cols-[300px_4fr_1fr_42px]'
          : 'md:grid-cols-[42px_4fr_42px]'
      "
    >
      <div
        :style="{ color: item.titleColor || undefined }"
        class="px-4 text-white md:col-start-2"
      >
        <div
          class="lg:text-2 text-3 text-center"
          v-html="$sanitize(item.title)"
        />
        <p
          v-if="item.description"
          class="text-center text-[10px] font-light opacity-60 sm:text-xs"
          v-html="$sanitize(item.description)"
        />
      </div>
      <Btn
        v-if="item.buttonHreff"
        :to="item.buttonHreff"
        :style="{
          backgroundColor: props.item.buttonColor || undefined,
          color: props.item.buttonTextColor || undefined,
        }"
        class="text-4 hover:text-primary-900 text-primary-400 flex w-max flex-col items-center justify-center gap-2.5 rounded-lg bg-white px-4 py-2 font-medium shadow-[0px_0px_30px_0px_#FFFFFF1A] md:rounded-xl md:px-6 md:py-2"
      >
        {{ item.buttonText || 'Узнать подробности' }}
      </Btn>
      <Btn
        :style="{ color: props.item.buttonColor || undefined }"
        class="text-primary absolute right-1 flex items-center justify-center rounded-full bg-white backdrop-blur-[0.5px] md:static md:h-[42px] md:w-[42px] md:rounded-xl md:bg-opacity-20 md:text-white md:hover:bg-opacity-40"
        @click="close"
      >
        <Icon name="close" class="h-6 w-6" />
      </Btn>
    </div>
  </article>
</template>

<script setup lang="ts">
import type { CrossBanner } from '@/openapi_fetch'

const props = defineProps<{
  item: CrossBanner
}>()
const emit = defineEmits(['close'])

const { $sanitize } = useNuxtApp()

function close() {
  emit('close', props.item)
}
</script>
